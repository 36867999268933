import React, { useRef, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BaseLayout from "../components/base-layout";
import bg from "../images/bg/4.png";
import Allura from "../images/brand/allura.png";
import Azarine from "../images/brand/azarine.png";
import Woman from "../images/brand/cmo.jpg";
import Heslin from "../images/brand/heslin.png";
import Rayou from "../images/brand/rayou.png";
import Rei from "../images/brand/rei.png";
import Skinvest from "../images/brand/skinvest.png";
import Zalina from "../images/brand/zalina.png";
import {
  BrandLogo,
  TestimoniWrapper,
  TestiPerson,
  TestiTitle,
} from "../styles/portfolio-style";

const Portfolio = () => {
  const verticalSlideRef = useRef(null);
  const horizontalSlideRef = useRef(null);
  const [job, setJob] = useState("CMO Cella Vanessa");
  const [brandName, setBrandName] = useState("Azarine");
  const [personImage, setPersonImage] = useState(Woman);

  var verticalSlideSettings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    vertical: true,
    verticalSwiping: true,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  var horizontalSlideSettings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: <React.Fragment></React.Fragment>,
    nextArrow: <React.Fragment></React.Fragment>,
  };

  return (
    <BaseLayout title='Our Portfolio' navColor='white'>
      <section
        className='seventh-section section'
        data-anchor='portfolio'
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <Container className='pt-3 pb-5'>
          {/* <p className="section-title">Our Portfolio</p> */}

          <Row>
            <Col xs={12} className='mb-3'>
              <TestimoniWrapper className='py-3 px-5 '>
                <h3 className='mb-3'>Clients Who Trust Us</h3>
                <div
                  className='d-flex align-items-center align-content-center flex-wrap'
                  style={{ justifyContent: "space-evenly" }}>
                  <BrandLogo
                    className='brand-img'
                    src={Azarine}
                    alt='brand icon'
                  />
                  <BrandLogo className='brand-img' src={Rei} alt='brand icon' />
                  <BrandLogo
                    className='brand-img'
                    src={Rayou}
                    alt='brand icon'
                  />
                  <BrandLogo
                    className='brand-img'
                    src={Skinvest}
                    alt='brand icon'
                  />
                  <BrandLogo
                    className='brand-img'
                    src={Zalina}
                    alt='brand icon'
                  />
                  <BrandLogo
                    className='brand-img'
                    src={Allura}
                    alt='brand icon'
                  />
                  <BrandLogo
                    className='brand-img'
                    src={Heslin}
                    alt='brand icon'
                  />
                </div>
              </TestimoniWrapper>
            </Col>

            <Col xs='12'>
              <TestimoniWrapper>
                <Row>
                  <Col sm='3' className='text-center'>
                    <Image
                      src={personImage}
                      fluid
                      style={{ borderRadius: 8 }}
                    />
                    <TestiPerson>{job}</TestiPerson>
                    <TestiPerson>{brandName}</TestiPerson>
                  </Col>

                  <Col sm='9'>
                    <TestiTitle>Mengapa Memilih WKI?</TestiTitle>
                    <span className='notranslate'>
                      Memulai bisnis di bidang kosmetik bukanlah hal mudah bagi
                      saya. Untung saya menemukan partner bisnis yang
                      profesional seperti WKI. Saya dapat berkonsultasi langsung
                      dengan tim yang ahli dan produk yang dihasilkan pun juga
                      berkualitas.
                    </span>
                  </Col>
                </Row>
              </TestimoniWrapper>
            </Col>
          </Row>
        </Container>
      </section>
    </BaseLayout>
  );
};

export default Portfolio;
