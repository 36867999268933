import styled from "styled-components";
import { BlueColor } from "./global-style";

export const TestimoniWrapper = styled.div`
  width: 100%;
  padding: 2em 3em;
  background-color: rgb(203 233 244 / 85%);
  color: ${BlueColor};
  border-radius: 16px;
`;

export const BrandLogo = styled.img`
  width: 100%;
  max-width: 83px;
  margin: 0 auto;
  cursor: pointer;
`;

export const TestiTitle = styled.p`
  font-family: "montserrat-bold";
  font-size: 1.5rem;
`;

export const TestiPerson = styled.p`
  color: black;
  margin: 0;
  font-style: italic;
`;
